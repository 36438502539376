<template>
  <v-container fluid>
    <v-card
      class="mx-lg-10 py-lg-10"
    >
      <v-row class="mx-lg-10">
        <v-col
          cols="6"
          sm="6"
          md="4"
        >
          <v-skeleton-loader
            class="pl-3 pl-md-0 rounded-lg"
            type="text"
          />
        </v-col>

        <v-spacer />

        <v-col
          cols="6"
          sm="6"
          md="4"
        >
          <v-skeleton-loader
            v-if="verification"
            class="d-flex justify-end pr-3 pl-md-0 rounded-lg"
            type="button"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-spacer class="py-md-3" />
      </v-row>

      <v-row
        v-for="x in rows"
        :key="x"
        class="mx-lg-10 py-3 custom-style-row"
      >
        <v-col
          v-for="y in columns"
          :key="y"
          cols="4"
          sm="6"
          md="2"
        >
          <v-skeleton-loader
            class="px-3 px-md-0 rounded-lg"
            type="text"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-spacer class="py-3" />
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['columns', 'rows', 'verification'],
    data: () => {
      return {
        name: 'LoaderDataTable',
      }
    },
    created () {
    //   console.log(this.columns)
    },
  }
</script>

<style scoped>
    .custom-style-row {
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .custom-style-row {
            margin-left: 12px;
            margin-right: 12px;
        }
    }
</style>
